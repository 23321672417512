<template>
  <div v-if="isDone" class="mt-6">
    <h2 class="mb-4 text-xl font-bold flex items-center">
      {{ data.studentResults.student.name }}

      <a
        v-if="isDev && me.kind === 'teacher'"
        class="text-sm font-normal ml-auto"
        :href="loginUrl"
      >
        Inloggen
      </a>

      <a
        v-else-if="data.studentResults.student.studentId"
        class="bg-black text-neutral-100 py-1 px-2 rounded-lg text-sm font-normal ml-auto flex gap-2"
        :href="`https://yos.nhlstenden.com/students/${data.studentResults.student.studentId}/progress`"
      >
        <span>Your Own Space</span>
        <arrow-top-right-on-square-icon class="w-4" />
      </a>
    </h2>

    <div class="space-y-6">
      <ul class="labels mb-2 -mx-4 px-4 flex gap-2 overflow-auto">
        <li
          v-if="data.studentResults.student.studentId && me.kind === 'teacher'"
        >
          Studentnummer:
          <strong>{{ data.studentResults.student.studentId }}</strong>
        </li>

        <li
          v-for="group in userGroups"
          :key="group.id"
          v-html="$t('groups.comingSemester', { title: group.title })"
        ></li>

        <li v-for="group in teacherGroups" :key="group.id">
          {{ $t('groups.mentor')
          }}<template v-if="teacherGroups.length > 0">
            <template v-if="group.slug === 'graduation'">
              ({{ $t('groups.graduation') }})</template
            >
            <template v-if="group.slug === 'intern'">
              ({{ $t('groups.internship') }})</template
            ></template
          >:
          <strong>{{ (group.teachers || [])[0]?.name }}</strong>
        </li>
      </ul>

      <div v-if="route.name === 'student' && me.kind === 'teacher'">
        <div class="space-y-6 max-w-4xl">
          <session-list kind="upcoming" :me="me" :student-id="studentId" />
          <session-list kind="past" :me="me" :student-id="studentId" />
        </div>
      </div>
    </div>

    <router-view :student="data.studentResults.student" />
  </div>
</template>

<script lang="ts" setup>
  import { computed, PropType, reactive } from 'vue'
  import { useQuery } from 'villus'
  import { useRoute } from 'vue-router'
  import { useHead } from '@vueuse/head'

  import SessionList from '../components/SessionList.vue'

  import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'

  import query from '../graphql/StudentProgress.graphql'

  defineProps({
    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const route = useRoute()

  const studentId = computed(() => route.params.studentId as string)
  const variables = reactive({ studentId: studentId })

  const isDev = import.meta.env.DEV
  const loginUrl =
    import.meta.env.VITE_APP_DEV_LOGIN_URL_STUDENT + '/' + studentId.value

  const { data, isDone } = useQuery({ query, variables })

  const userGroups = computed(() => {
    const allGroups: Group[] = data.value.studentResults.student.groups
    return allGroups.filter((g) => !g.teachers?.length)
  })

  const teacherGroups = computed(() => {
    const allGroups: Group[] = data.value.studentResults.student.groups
    return allGroups.filter((g) => g.slug)
  })

  useHead({
    title: () => (data.value ? data.value.studentResults.student.name : null),
  })
</script>

<style lang="postcss" scoped>
  .labels li {
    @apply bg-white text-sm text-gray-800 border-gray-300 border inline-block px-2 py-1 rounded-md whitespace-nowrap;
  }
</style>
