<template>
  <home-content>
    <template #title>{{ $t('entities.session.plural') }}</template>

    <p v-if="me.kind == 'teacher'" class="mb-6">
      <pill-button
        :title="
          $t('actions.create', { entity: $t('entities.session.singular') })
        "
        :route="{ name: 'new_session' }"
      />
    </p>

    <div class="space-y-6 max-w-4xl">
      <session-list kind="upcoming" :me="me" />
      <session-list kind="past" :me="me" />
    </div>
  </home-content>
</template>

<script lang="ts" setup>
  import HomeContent from '../components/HomeContent.vue'
  import SessionList from '../components/SessionList.vue'
  import PillButton from '../components/PillButton.vue'

  import { PropType } from 'vue'
  import { useHead } from '@vueuse/head'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  defineProps({
    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const title = t('entities.session.plural')
  useHead({ title: title.charAt(0).toUpperCase() + title.substr(1) })
</script>
