<template>
  <header class="p-4 bg-white md:p-14 md:pt-28 md:border-b md:border-gray-200">
    <div class="max-w-4xl">
      <div class="md:grid md:grid-cols-5">
        <div class="md:col-span-3">
          <div class="md:-mt-10 mb-4 flex">
            <session-sidebar-toggler
              :show-sidebar="showSidebar"
              @show:sidebar="emit('show:sidebar', $event)"
            />

            <span v-if="session.enabled" class="ml-4 space-x-2 flex">
              <icon-button
                :icon="PencilIcon"
                :to="{
                  name: 'edit_session',
                  params: { sessionId: session.id },
                }"
              />
              <icon-button
                :icon="TrashIcon"
                @click="emit('delete:session', session.id)"
              />
            </span>
          </div>

          <h2 class="md:text-3xl">{{ session.title }}</h2>
          <h3 class="text-gray-500">
            {{ $t(`entities.session.kinds.${session.kind}`) }}
          </h3>
        </div>

        <div class="my-3 md:col-span-2 md:my-0 md:text-right">
          <template v-if="session.owned">
            <template v-if="session.state === 'active'">
              <span
                v-if="isDisabled"
                class="text-xs inline-block"
                v-html="$t('sessions.finalize.mergeFirst')"
              />

              <pill-button
                v-else
                :disabled="isDisabled"
                :title="$t('sessions.finalize.start')"
                @click="startFinalizing"
              />
            </template>

            <pill-button
              v-if="session.state === 'finalizing'"
              :title="$t('sessions.finalize.finalize')"
              @click="finalizeSession"
            />
          </template>

          <template v-else>
            <span
              v-if="session.state === 'finalizing'"
              class="text-xs inline-block"
            >
              {{ $t('sessions.finalize.pending') }}
            </span>
          </template>
        </div>
      </div>

      <div
        class="mt-2 space-y-2 md:grid md:grid-cols-4 md:mt-4 md:space-y-0 md:text-sm"
      >
        <div>
          <h4 class="text-xs text-gray-500 md:mb-1">
            {{ $t('entities.session.startingAt') }}
          </h4>
          {{ formattedTimestamp(session.startingAt) }}
        </div>

        <div>
          <h4 class="text-xs text-gray-500 md:mb-1">
            {{ $t('entities.session.context') }}
          </h4>
          {{ session.context }}
        </div>

        <div class="col-span-2">
          <h4 class="text-xs text-gray-500 md:mb-1">
            {{
              $t(
                `entities.session.teachers.${
                  session.teachers.length > 1 ? 'plural' : 'singular'
                }`,
              )
            }}
          </h4>

          <div class="space-y-1">
            <user-name
              v-for="user in session.teachers"
              :key="`header-teacher-${user.id}`"
              :user="user"
              :show-name="true"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'
  import { formattedTimestamp } from '../utils.js'

  import { PencilIcon, TrashIcon } from '@heroicons/vue/24/solid'

  import IconButton from './IconButton.vue'
  import PillButton from './PillButton.vue'
  import SessionSidebarToggler from './SessionSidebarToggler.vue'
  import UserName from './UserName.vue'

  const props = defineProps({
    session: {
      type: Object as PropType<Session>,
      required: true,
    },

    showSidebar: {
      type: Boolean,
      required: true,
    },
  })

  const emit = defineEmits([
    'finalize:session',
    'start_finalizing:session',
    'show:sidebar',
    'delete:session',
  ])

  const isDisabled = computed(() => {
    const goals = props.session.goals

    const hasGoals = goals.length > 0
    const allGoalsHaveResults = goals.every((goal) => goal.result)

    return !(hasGoals && allGoalsHaveResults)
  })

  const startFinalizing = () => {
    if (isDisabled.value) {
      alert(
        'Je kunt pas samenvoegen als alle competenties een beoordeling hebben.',
      )
    } else {
      emit('start_finalizing:session')
    }
  }

  const finalizeSession = () => {
    const allGoalsHavePosts = props.session.goals.every((goal) => {
      const posts = props.session.posts.filter((p) => {
        const a = p.goal?.id === goal.id

        const div = document.createElement('div')
        div.innerHTML = p.body
        const text = div.textContent || div.innerText || ''
        const b = text.length

        return a && b
      })

      return posts.length
    })

    if (!allGoalsHavePosts) {
      alert(
        'Je kunt pas definitief maken als alle competenties feedback hebben.',
      )
    } else {
      emit('finalize:session')
    }
  }
</script>
