<template>
  <form class="space-y-3" @submit.prevent="createGoals">
    <div
      v-if="sessionGoals.goals.length === 0 && showTemplates"
      class="mb-3 -mt-2 bg-gray-100 py-1 px-2 text-gray-400 border border-gray-300 text-sm rounded-md"
    >
      <span class="font-bold">Templates:</span>

      <div class="flex gap-2 items-center mt-1">
        <select v-model="selectedTemplate" class="!mt-0">
          <option :value="null">{{ $t('sessions.templates.select') }}</option>
          <option
            v-for="template in templates"
            :idx="`template-${template.id}`"
            :value="template.id"
          >
            {{ template.title }}
          </option>
        </select>

        <a @click="addTpl" class="text-white bg-sky-500 py-1 px-2 rounded-md">
          {{ $t('system.add') }}
        </a>
      </div>
    </div>

    <div
      v-for="(goal, idx) in sessionGoals.goals"
      :key="`session-goal-${goal.id || goal.key}`"
      class="p-4 bg-gray-100 rounded-md"
    >
      <p class="grid grid-cols-3 gap-2">
        <select v-model="goal.competencyId" required>
          <option value="">
            {{
              $t('system.select', { title: $t('entities.competency.singular') })
            }}
          </option>
          <optgroup :label="$t('system.options')">
            <option
              v-for="competency in competencies"
              :key="`session-competency-${competency.id}`"
              :value="competency.id"
            >
              {{ locale === 'nl' ? competency.title : competency.titleEn }}
            </option>
          </optgroup>
        </select>

        <template v-if="goal.competencyId">
          <select v-model="goal.amount" required>
            <option :value="0">
              {{ $t('system.select', { title: $t('entities.goal.amount') }) }}
            </option>
            <optgroup :label="$t('system.options')">
              <option
                v-for="amount in amounts(goal.competencyId)"
                :key="`${goal.competencyId}-amount-${amount}`"
                :value="amount"
              >
                {{ amount }} EC
              </option>
            </optgroup>
          </select>

          <select v-model="goal.level" required>
            <option value="">
              {{ $t('system.select', { title: $t('entities.goal.level') }) }}
            </option>
            <optgroup :label="$t('system.options')">
              <option
                v-for="level in levels(goal.competencyId)"
                :key="`${goal.competencyId}-level-${level}`"
                :value="level"
              >
                Nv.{{ level }}
              </option>
            </optgroup>
          </select>
        </template>
      </p>

      <p class="bg-white border border-gray-300 mt-2">
        <rich-text-editor
          :enabled="true"
          :current="true"
          :body="goal.description"
          @save:post="goal.description = $event.body"
        />
      </p>

      <p class="mt-3">
        <icon-button
          :icon="XMarkIcon"
          :title="$t('goals.removeGoal')"
          @click="sessionGoals.goals.splice(idx, 1)"
        />
      </p>
    </div>

    <p>
      <icon-button
        :icon="PlusIcon"
        :title="$t('goals.addGoal')"
        @click="addGoal"
      />
    </p>

    <p class="flex items-center">
      <pill-button v-if="enabled" :title="$t('system.save')" />
      <a class="ml-auto cursor-pointer underline" @click="emit('dismiss')">{{
        $t('system.cancel')
      }}</a>
    </p>
  </form>
</template>

<script lang="ts" setup>
  import { PropType, reactive, ref } from 'vue'

  import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/solid'
  import IconButton from './IconButton.vue'
  import PillButton from './PillButton.vue'
  import RichTextEditor from './RichTextEditor.vue'
  import { v4 } from 'uuid'
  import { useI18n } from 'vue-i18n'

  const { locale } = useI18n()

  const emit = defineEmits(['create:goals', 'dismiss'])

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    enabled: {
      type: Boolean,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },

    showTemplates: {
      type: Boolean,
      default: () => false,
    },
  })

  const sessionGoals: NewGoals = reactive({
    sessionId: props.session.id,
    goals: [],
  })

  const goals = props.session.goals
    .filter((g: Goal) => g.student.id === props.me.id)
    .map((g: Goal) => {
      return {
        competencyId: g.competency.id,
        amount: g.amount,
        id: g.id,
        level: g.level,
        description: g.description,
        key: v4(),
      }
    })

  sessionGoals.goals = goals

  const addGoal = () => {
    sessionGoals.goals.push({
      amount: 0,
      level: '',
      competencyId: '',
      description: '',
    })
  }

  const amounts = (competencyId: string) => {
    const competency = props.competencies.find((c) => c.id === competencyId)
    if (competency) {
      return competency.amounts
    } else {
      return []
    }
  }

  const competencyIds = props.competencies.reduce(
    (lookup: { [key: string]: string }, competency) => {
      lookup[competency.title] = competency.id
      return lookup
    },
    {},
  )

  const selectedTemplate = ref<null | string>(null)

  const templates = [
    {
      title: 'Propedeuse P1 (NL)',
      id: 'ad1f681bf-c8b9-492d-9891-66eade5b3bd3',
      goals: [
        {
          amount: 6,
          level: '1',
          competencyId: competencyIds['Creëren'],
          description: `
            <p>Aan het einde van de eerste periode ben ik in staat om:</p>
            <ul>
              <li>inspiratiemateriaal te gebruiken om tot ideeën en ontwerpen te komen.</li>
              <li>tot één of meerdere ideeën te komen vanuit gegeven opdrachten.</li>
              <li>er bewust van te zijn dat er altijd meer dan één oplossing is voor een opdracht om op die manier een open mindset te ontwikkelen.</li>
              <li>één of meerdere ideeën uit te werken tot ontwerpen (schetsen, wireframes, audioplannen, verhalen, storyboards etc.) om mijn ideeën te visualiseren.</li>
              <li>meerdere soft- en hardware te herkennen waarmee ik kan werken om een resultaat te leveren.</li>
              <li>ontwerpen uit te werken richting (deel)producten en/of prototypes zodat deze kan tonen aan peers/stakeholders voor feedback.</li>
            </ul>
          `,
        },
        {
          amount: 3,
          level: '1',
          competencyId: competencyIds['Communiceren'],
          description: `
            <p>Aan het einde van deze periode ben ik in staat om:</p>
            <ul>
              <li>mezelf en mijn werk op verschillende manieren te presenteren in mijn community.</li>
              <li>met peers en consultants te communiceren over design opdrachten om mijn werk te verbeteren.</li>
              <li>een visuele boodschap te maken door woord en beeld met elkaar te verbinden om de boodschap goed over te brengen.</li>
            </ul>
          `,
        },
        {
          amount: 6,
          level: '1',
          competencyId: competencyIds['Leren'],
          description: `
            <p>Aan het einde van de eerste periode ben ik in staat om:</p>
            <ul>
              <li>een beeld te hebben van de (multimedia) designwerelden zodat ik een gemotiveerde keuze kan maken waar je aan verder wilt werken.</li>
              <li>een eigen aanpak te herkennen wanneer ik voor nieuwe taken komt te staan.</li>
              <li>te reflecteren op mijn werk om verbeterpunten te formuleren.</li>
              <li>peers te ondersteunen bij hun reflectie op hun werk.</li>
              <li>mijn tijd te plannen in relatie tot de opdracht die ik gekozen heb om verder uit te werken.</li>
              <li>mijn interesses en ambities in multimedia design te herkennen door een Droomportfolio te ontwikkelen.</li>
              <li>inzicht te geven in mijn ontwikkeling per competentie met behulp van het Groeiportfolio</li>
              <li>een eerste versie van mijn  Designportfolio op te zetten om mezelf als beginnend ontwerper bekend te maken aan de wereld</li>
            </ul>
          `,
        },
      ],
    },

    {
      title: 'Propedeuse P1 (EN)',
      id: '558a31fa-b916-43cf-aeb2-9f4892d70b88',
      goals: [
        {
          amount: 6,
          level: '1',
          competencyId: competencyIds['Creëren'],
          description: `
            <p>After the first period I am able to…</p>
            <ul>
              <li>use inspirational materials to help me to come up with ideas and designs.</li>
              <li>generate one or more ideas to complete given assignments.</li>
              <li>be aware that there is always more than one solution to a challenge in order to develop an open mindset.</li>
              <li>develop one or more ideas into designs (sketches, wireframes, audio plans, stories, storyboards etc.) to visualise my ideas.</li>
              <li>recognise there is multiple software and hardware I can work with to deliver a result.</li>
              <li>develop designs towards (sub)products and/or prototypes in order to present them to peers/stakeholders for feedback.</li>
            </ul>
          `,
        },
        {
          amount: 3,
          level: '1',
          competencyId: competencyIds['Communiceren'],
          description: `
            <p>At the end of the first period I am able to…</p>
            <ul>
              <li>Use different forms of presenting myself and my work in my community.</li>
              <li>communicate with peers and consultants about design challenges to improve my work.</li>
              <li>make a visual message by connecting word and image in order to convey the message correctly.</li>
            </ul>
          `,
        },
        {
          amount: 6,
          level: '1',
          competencyId: competencyIds['Leren'],
          description: `
            <p>At the end of the first period I am able to…</p>
            <ul>
              <li>have a basic understanding of the worlds of (multimedia) design which will help me to make a motivated choice where I want to work on.</li>
              <li>recognize my own approach when I am confronted with new tasks.</li>
              <li>plan my time in relation to the assignment I chose to elaborate.</li>
              <li>reflect on my work in order to formulate points of improvement.</li>
              <li>support peers with their reflections on their work. </li>
              <li>set up a first version of my Design Portfolio in order to make myself known to the external world/working field.</li>
              <li>recognize my interests and aspirations in multimedia by developing a Dream portfolio. </li>
              <li>Give insight in my development per competency with the help of the Growth portfolio.</li>
            </ul>
          `,
        },
      ],
    },

    {
      title: 'Propedeuse P2',
      id: '360de3d0-0e29-43f8-84a2-04204ef47826',
      goals: [
        {
          amount: 6,
          level: '1',
          competencyId: competencyIds['Onderzoeken'],
          description: 'Geen leerdoelen.',
        },
        {
          amount: 3,
          level: '1',
          competencyId: competencyIds['Organiseren'],
          description: 'Geen leerdoelen.',
        },
        {
          amount: 6,
          level: '1',
          competencyId: competencyIds['Creëren'],
          description: 'Geen leerdoelen.',
        },
      ],
    },
  ]

  const addTpl = () => {
    const template = templates.find((t) => t.id === selectedTemplate.value)

    if (template) {
      template.goals.forEach((goal) => {
        sessionGoals.goals.push(goal)
      })
    }
  }

  const levels = (competencyId: string) => {
    const competency = props.competencies.find((c) => c.id === competencyId)
    if (competency) {
      return competency.levels
    } else {
      return []
    }
  }

  const createGoals = () => {
    sessionGoals.goals = sessionGoals.goals.filter((g) => {
      return ['competencyId', 'amount', 'level', 'description'].every(
        (field) => {
          return String(g[field as keyof GoalInput]).length > 0
        },
      )
    })

    emit('create:goals', sessionGoals)
  }
</script>

<style lang="postcss" scoped>
  :deep(label) {
    @apply text-sm;
  }

  :deep(input),
  :deep(select),
  :deep(textarea) {
    @apply bg-white border border-gray-300 block w-full py-1 px-2 appearance-none rounded-none mt-1;
  }
</style>
